.containerPaper {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerPaper h1 {
    font-size: 18px;
}